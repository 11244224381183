





















































import { Component, Vue, Watch } from 'vue-property-decorator'
import fillStore from '@/services/fillStore'
import { AuthModule } from '@/store/modules'

@Component
export default class TwoFactorAuthModal extends Vue {
  @AuthModule.Action login

  showModal = false
  userData = {}
  qrCodeURL = ''
  twoFactorAuthCode = ''
  backUpCode = null
  showBackUpCodeInput = false
  currentInput = 0
  showLoading = false
  error = {
    state: false,
    message: ''
  }
  keyCodes = Array.from({ length: 10 }, (_, i) => `Digit${i}`)

  @Watch('twoFactorAuthCode')
  @Watch('backUpCode')
  onCodeChange() {
    this.error = {
      state: false,
      message: ''
    }
  }

  get isTwoFactorCodeValid() {
    return this.twoFactorAuthCode.length === 6
  }

  mounted() {
    this.$root.$on('openTwoFactorAuthModal', async (userData) => {
      this.showModal = true
      this.userData = userData
    })
  }

  changeShowBackUpCodeInputState(state: boolean) {
    this.showBackUpCodeInput = state
  }

  closeModal() {
    this.twoFactorAuthCode = ''
    this.backUpCode = null
    this.changeShowBackUpCodeInputState(false)
  }

  async verify2FACode() {
    if (!this.backUpCode && !this.isTwoFactorCodeValid)
      return

    this.showLoading = true

    const data = await this.login({
      ...this.userData,
      twoFactorCode: this.twoFactorAuthCode || this.backUpCode
    })


    if (!data.hasOwnProperty('token')) {
      this.error.state = true
      this.error.message = data.key

      this.showLoading = false
      return
    }

    await fillStore()
    this.$router.push('/')
  }
}
